<template>
  <BaseModal alignment="center" :is-visible="visible">
    <CModalHeader>
      <CModalTitle>Внимание!</CModalTitle>
    </CModalHeader>
    <CModalBody class="question">
      <CIcon size="lg" icon="cil-info"/> Вы действительно хотите добавить абонента во Froud лист?
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="close">
        Отмена
      </CButton>
      <CButton color="primary" @click="confirm">Да</CButton>
    </CModalFooter>
  </BaseModal>
</template>

<script>
import BaseModal from "../../widgets/BaseModal";
export default {
  name: "BanUserModal",
  components: {BaseModal},
  emits: ['close', 'confirm'],
  props: {
    isWindowVisible: false,
  },
  data() {
    return {
      visible:false,
    }
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('close')
    },
    confirm() {
      this.visible = false
      this.$emit('confirm')
    }
  },
  watch: {
    isWindowVisible: function(val) {
      this.visible = val
    }
  }
}
</script>

<style scoped>
  .question {
    margin: 0 auto;
  }
</style>
