<template>
    <SmcBaseTable
      :response-data="responseData"
      :add-checkboxes="false"
      :is-loading="isLoading"
    />
</template>

<script>

import SmcBaseTable from "./SmcBaseTable";
import {ref} from "vue";
import {closeSmc, getSmcActive, getSmcCharge, getSmcInactive, getSmcOnetime, getUsers} from "../../../services/api";
import UserActionsBar from "../../configurator/users/action-bar/UserActionsBar";

export default {
  name: "SmcCharge",
  components: { SmcBaseTable },
  props: {
    selectedUserNumber: String
  },
  setup(props) {
    let responseData = ref({})
    getSmcCharge(props.selectedUserNumber).then(r=>responseData.value = r)
    return {
      responseData
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  watch: {
    selectedUserNumber: function(val) {
      // this.selectedUserNumber = val
      this.isLoading = true
      getSmcCharge(val).then(response => {
        this.responseData = response
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
