<template>
  <FilterWidget
    :filters="filters"
    @search="search"
  />
  <SmcBaseTable
    :response-data="responseData"
    :add-checkboxes="false"
    :is-loading="isLoading"
  />
</template>

<script>
import FilterWidget from "../../widgets/FilterWidget";
import SmcBaseTable from "./SmcBaseTable";
import {ref} from "vue";
import {
  getSmcSms,
} from "../../../services/api";

export default {
  name: "SmcSms",
  components: {SmcBaseTable, FilterWidget},
  props: {
    selectedUserNumber: String
  },

  setup(props) {
    let responseData = ref({})
    let searchFields = ref({})
    let isLoading = ref(false)
    let userPhone = ref(props.selectedUserNumber)
    //test number 77012113554
    function reload() {
      isLoading.value = true
      getSmcSms({...{msisdn: userPhone.value}, ...searchFields.value}).then(r => {
        responseData.value = r
        isLoading.value = false
      })
    }

    reload()
    return {
      responseData,
      userPhone,
      searchFields,
      reload
    }
  },
  data() {
    return {
      filters: [
        {
          name: 'period',
          type: 'daterange',
          format: 'YYYY-MM-DD',
          data: {
            start: '2021-01-01',
            end: (new Date()).getFullYear() + '-12-31'
          }
        },
        {
          name: 'submit',
          label: 'Найти',
          type: 'button'
        }
      ]
    }
  },
  methods: {
    search(fields) {
      this.searchFields = fields
      this.reload()
    },
  },
  watch: {
    selectedUserNumber: function (val) {
      this.userPhone = val
      this.reload()
      console.log("selectedUserNumber", val)
    }
  }
}
</script>

<style scoped>

</style>
