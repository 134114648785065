<template>

    <UserActionsBar
      :availableActions="actions"
      @barAction="clickActionBar"
      :selected-items-count="this.selectedItems.length"
      :is-search-visible="false"
      :is-smc-type-visible="true"
      v-if="isTableVisible"
    />
    <SmcBaseTable
      :response-data="responseData"
      @selectedRows="selectedRows"
      :add-checkboxes="true"
      :is-loading="isLoading"
      @showData="isTableVisible = true"
      @showEmpty="isTableVisible = true"
      @showWelcome="isTableVisible = false"
    />
</template>

<script>

import SmcBaseTable from "./SmcBaseTable";
import {ref} from "vue";
import {closeSmc, getSmcActive, getSmcInactive, getSmcOnetime, getUsers} from "../../../services/api";
import UserActionsBar from "../../configurator/users/action-bar/UserActionsBar";

export default {
  name: "SmcProducts",
  components: { SmcBaseTable, UserActionsBar },
  props: {
    selectedUserNumber: String
  },
  setup(props) {
    let responseData = ref({})
    getSmcActive(props.selectedUserNumber).then(r=>responseData.value = r)
    return {
      responseData
    }
  },
  data() {
    return {
      isTableVisible: false,
      selectedItems: [],
      isLoading: false,
      selectedSmcType: "activeSmc",
      actions: ['unsubscribe'],
    }
  },
  methods: {
    loadSmc() {
      console.log(this.selectedSmcType)
      switch (this.selectedSmcType) {
        case "activeSmc":
          this.actions = ['unsubscribe']
          getSmcActive(this.selectedUserNumber).then(response => {
            this.responseData = response
            this.isLoading = false
          })
          break
        case "inactiveSmc":
          this.actions = []
          getSmcInactive(this.selectedUserNumber).then(response => {
            this.responseData = response
            this.isLoading = false
          })
          break
        case "onetime":
          this.actions = []
          getSmcOnetime(this.selectedUserNumber).then(response => {
            this.responseData = response
            this.isLoading = false
          })
          break
      }
    },
    selectedRows(rows) {
      this.selectedItems = rows
    },
    clickActionBar(action) {
      switch (action) {
        case "activeSmc":
        case "inactiveSmc":
        case "onetime":
          this.selectedSmcType = action
          if (this.selectedUserNumber) {
            this.loadSmc()
          }
          break
        case "unsubscribe":
          let ids = this.selectedItems.map( item => { return item.ID} )
          this.selectedItems = []
          closeSmc(ids, this.selectedUserNumber).then(
            r => this.loadSmc()
          )
          break
        default:
          break
      }
    }
  },
  watch: {
    selectedUserNumber: function(val) {
      // this.selectedUserNumber = val
      this.loadSmc()
    }
  }
}
</script>

<style scoped>

</style>
