<template>
  <CSpinner v-if="isLoading" style="position: absolute;  top: 50%; left: 50%;"/>
  <div v-else>
  <CCard class="mb-4">
    <CNav class="nav-underline">
      <CNavItem v-for="(tab) in tabs">
        <CNavLink @click="selectTab(tab.value)" :active="selectedTab === tab.value"> {{ tab.label }} </CNavLink>
      </CNavItem>
      <CNavItem>
        <CInputGroup>
          <CFormInput
            placeholder="7011234567"
            :value="userNumber"
            @input="userNumber = $event.target.value"
            type="phone" id="phone"/>
          <CButton type="button" color="primary" @click="selectUserNumber"><CIcon name="cil-search"/></CButton>
          <CButton type="button" color="danger" :disabled="!this.selectedUserNumber" @click="showBanUserModal"><CIcon name="cil-ban"/></CButton>
        </CInputGroup>
      </CNavItem>
    </CNav>

    <SmcProducts v-if="selectedTab === 0" :selected-user-number="selectedUserNumber"/>
    <SmcCharge v-if="selectedTab === 2" :selected-user-number="selectedUserNumber"/>
    <SmcSms v-if="selectedTab === 3" :selected-user-number="selectedUserNumber"/>
    <SmcSmpp v-if="selectedTab === 4" :selected-user-number="selectedUserNumber"/>
    <SmcLog v-if="selectedTab === 5" :selected-user-number="selectedUserNumber"/>
<!--    <MenusTable v-if="selectedTab === 3" />-->
    <BanUserModal
      :is-window-visible="isBanUserVisible"
      @close="closeBanUserModal"
      @confirm="banUser"
    />
  </CCard>
  </div>
</template>

<script>

import SmcBaseTable from "./SmcBaseTable";
import UserActionsBar from "../../configurator/users/action-bar/UserActionsBar";
import SmcProducts from "./SmcProducts";
import SmcCharge from "./SmcCharge";
import SmcSms from "./SmcSms";
import SmcSmpp from "./SmcSmpp";
import SmcLog from "./SmcLog";
import BanUserModal from "./BanUserModal";
import {banSmcUser, getSmc} from "../../../services/api";
import {ref} from "vue";

export default {
  name: "EcupRoot",
  components: {BanUserModal, SmcLog, SmcSmpp, SmcSms, SmcCharge, SmcProducts, SmcBaseTable, UserActionsBar },
  data() {
    return {
      isBanUserVisible: false,
      userNumber:"",
      selectedUserNumber:"",
      selectedTab: 0,
    }
  },
  setup() {
    let isLoading = ref( true)
    let tabs = ref([])
    getSmc().then(r => {
      isLoading.value = false
      tabs.value = r.data[0].result.values
    })
    return {
      isLoading,
      tabs
    }
  },
  methods: {
    selectUserNumber() {
      console.log("selectUserNumber", this.userNumber)
      this.selectedUserNumber = this.userNumber
    },
    selectTab(index) {
      this.selectedTab = index
    },
    showBanUserModal() {
      this.isBanUserVisible = true
    },
    closeBanUserModal() {
      this.isBanUserVisible = false
    },
    banUser() {
      banSmcUser(this.selectedUserNumber).then(r => console.log("banSmcUser ok"))
    }
  },
}
</script>

<style scoped>

</style>
